import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import axios from 'axios';
import './Hero.css';

const Hero = () => {
  const { t, i18n } = useTranslation();
  const [heroData, setHeroData] = useState(null);
  const route = i18n.language === 'tr' ? '/projeler' : '/en/projects';

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/banner/list/${i18n.language}`);
        
        setHeroData(response.data);
      } catch (error) {
        console.log('Hata:', error);
      }
    };

    fetchHeroData();
  }, [i18n.language]);

  if (!heroData) return <div></div>;  // Veri yükleniyor bildirimi
  

  return (
    <div className="container-fluid">
      <div className="sectionOne">
        <div className="home-left">
          <h1>{heroData[0].title}</h1>
          <p>{heroData[0].description}</p>
          
          <button className="button-gradient">
            <div className="bg-btn-wrapper">
              <div className="btn-bg bg-1"></div>
              <div className="btn-bg bg-2"></div>
              <div className="btn-bg bg-3"></div>
            </div>
            <Link to={route}>
            <div className="button-text">
              {heroData[0].buttonText} <BsArrowRight />
            </div>
            </Link>
          </button>
        </div>
        <div className="home-right">
          <img src={`${process.env.REACT_APP_API_URL}/${heroData[0].image_url}`} alt={heroData[0].title} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
